<template>
  <div class="zhibo_Phone"
  v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <!-- 面包屑 -->
    <el-row type="flex" justify="center" class="crumbs">
      <el-col >
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">社科视频首页</el-breadcrumb-item>
          <el-breadcrumb-item>直播</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="live" v-if="playerOptions.sources[0].src||GROUP_COVER">
        <el-col >
          <Headline title="当前直播"/>
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @pause="onPlayerPause($event)"
            @play="onPlayerPlay($event)"
            @ended="onPlayerEnded($event)"
            v-if="playerOptions.sources[0].src"
          ></video-player>
          <img v-else :src="GROUP_COVER" alt="" class="GROUP_COVER">
        </el-col>
      </el-row>
    <div class="container">
      <el-row type="flex" justify="center">
        <el-col >
          <!-- 副标题 -->
          <Headline title="往期回放" />

          <el-row :gutter="$store.state.isEquipment == 'pc'?20:0">
            <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6" v-for="item in list" :key="item.index">
              <router-link target="_blank" :to="{ path: item.pub_content_type==='GROUP_VIDEO'?'/shipinzu':'/xq', query: { qing: item.id, mian:'nzhi'} }">
                <div class="card">
                  <img  v-lazy="item.pub_cover" alt="" />
                  <div class="card_content">
                    <p>{{ item.SYS_TOPIC }}</p>
                  </div>
                </div>
              </router-link>
            </el-col>
          </el-row>

        </el-col>
      </el-row>
    </div>

    <!-- <Footer/> -->
    <div class="loading">
      <p class="busy" v-show="busy">加载中...</p>
      <p class="finish" v-show="finish">没有更多了</p>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import Headline from "@/components/ListHeadline.vue";

export default {
  name: "",
  components: {
    Headline,
  },

  data() {
    return {
      list: [],
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
      playerOptions: {
        muted: false, // 默认情况下将会消除任何音频。
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "application/x-mpegURL",
          src: '' //url地址
        }],
        poster: '', //你的封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },
      GROUP_COVER:''
    };
  },
  created(){
    this.getLive();
  },
  methods: {
    getLive(){
      let params = {
        s: 2,
        c: 79,
        pageNo: 1,
        pageSize: 10,
      }
      api
      .post("spc/cms/publish/queryList.do", params)
      .then((res) => {
        console.log(res);
        this.playerOptions.sources[0].src=res.result[0]?.live_captureAddress;
        this.GROUP_COVER = res.result[0]?.pub_picBig;
      });
    },
    load() {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData() {
      if(this.finish){
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 80,
          pageNo: this.pageNo,
          pageSize: 12,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.zhibo_Phone {
  background: #f9f9f9;
  padding:0 0.5rem;

  .crumbs {
    padding-top: 1.25rem;
  }
  .container {
    .card {
      margin-top: 1rem;
      padding: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      // width: 20rem;
      // height: 21rem;
      cursor: pointer;
      img {
        width: 100%;
        // height: 12.5rem;
      }
      .card_content {
        margin-top: 0.5rem;
        // margin-bottom: 0.5rem;
        text-align: center;
        p {
          font-size: 1.25rem;
          font-weight: bold;
          color: #333333;
          line-height: 2.25rem;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .loading {
    margin: 0.5rem 0;
    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
  .GROUP_COVER{
    width:90%;
    margin:1rem 5% 0;
    vertical-align: top;
  }
}
</style>