var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zhiboList"},[_c('div',{staticClass:"mainWidth"},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("社科视频首页")]),_c('el-breadcrumb-item',[_vm._v("直播")])],1)],1)],1),(_vm.playerOptions.sources[0].src||_vm.GROUP_COVER)?_c('el-row',{staticClass:"live",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('Headline',{attrs:{"title":"当前直播"}}),(_vm.playerOptions.sources[0].src)?_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":true,"options":_vm.playerOptions},on:{"pause":function($event){return _vm.onPlayerPause($event)},"play":function($event){return _vm.onPlayerPlay($event)},"ended":function($event){return _vm.onPlayerEnded($event)}}}):_c('img',{staticClass:"GROUP_COVER",attrs:{"src":_vm.GROUP_COVER,"alt":""}})],1)],1):_vm._e(),_c('div',{staticClass:"container"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('Headline',{attrs:{"title":"往期回放"}}),_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},_vm._l((_vm.list),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":6,"md":6,"lg":6,"xl":6}},[_c('router-link',{attrs:{"target":"_blank","to":{
                  path: item.pub_content_type==='GROUP_VIDEO'?'/shipinzu':'/xq',
                  query: {
                    qing: item.id,
                    mian: 'nzhi',
                  },
                }}},[_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',{staticClass:"card_content"},[_c('p',[_vm._v(_vm._s(item.SYS_TOPIC))])])])])],1)}),1),_c('Paging',{attrs:{"value":_vm.value},on:{"transfer":_vm.getData}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }