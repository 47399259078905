<template>
  <div class="zhiboList">
    <div class="mainWidth">
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }"
              >社科视频首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>直播</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="center"
        class="live"
        v-if="playerOptions.sources[0].src||GROUP_COVER"
      >
        <el-col>
          <Headline title="当前直播" />
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @pause="onPlayerPause($event)"
            @play="onPlayerPlay($event)"
            @ended="onPlayerEnded($event)"
            v-if="playerOptions.sources[0].src"
          ></video-player>
          <img v-else :src="GROUP_COVER" alt="" class="GROUP_COVER">
        </el-col>
      </el-row>

      <div class="container">
        <el-row type="flex" justify="center">
          <el-col>
            <!-- 副标题 -->
            <Headline title="往期回放" />

            <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
              <el-col
                :xs="24"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                v-for="item in list"
                :key="item.index"
              >
                <router-link
                  target="_blank"
                  :to="{
                    path: item.pub_content_type==='GROUP_VIDEO'?'/shipinzu':'/xq',
                    query: {
                      qing: item.id,
                      mian: 'nzhi',
                    },
                  }"
                >
                  <div class="card">
                    <img v-lazy="item.pub_cover" alt="" />
                    <div class="card_content">
                      <!-- lyd -->
                      <p>{{ item.SYS_TOPIC }}</p>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>

            <!-- 分页 -->
            <Paging :value="value" @transfer="getData" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import Headline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  name: "",
  components: {
    Headline,
    Paging,
    // Footer
  },

  data() {
    return {
      list: [],
      value: {
        path: "80",
        amount: "12",
      },
      playerOptions: {
        // controls: true,//控件显示隐藏
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        // autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        // loop: false, // 导致视频一结束就重新开始。
        // preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        // language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      GROUP_COVER:'',//组图新闻（直播现在的效果是有直播显示直播画面，没直播隐藏。改成有直播显示直播，没直播显示图片）
    };
  },
  created() {
    this.getLive();
  },
  methods: {
    getData(msg) {
      this.list = msg;
    },
    getLive() {
      let params = {
        s: 2,
        c: 79,
        pageNo: 1,
        pageSize: 10,
      };
      api.post("spc/cms/publish/queryList.do", params).then((res) => {
        console.log(111,res)
        this.playerOptions.sources[0].src = res.result[0]?.live_captureAddress;
        this.GROUP_COVER = res.result[0]?.pub_picBig;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.video-player {
  margin-top: 1rem;
}
.zhiboList {
  background: #f9f9f9;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      margin-top: 1rem;
      padding: 1rem 1rem 0.5rem 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      // width: 20rem;
      // height: 21rem;
      cursor: pointer;

      img {
        width: 100%;
        // height: 12.5rem;
        aspect-ratio: 4/3;
        vertical-align: top;
        height: 227px;
      }

      .card_content {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        text-align: center;

        p {
          font-size: 1.25rem;
          font-weight: bold;
          color: #333333;
          line-height: 2.25rem;
          height: 4.5rem;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .paging {
      margin-top: 3rem;
      margin-bottom: 3.75rem;
      text-align: center;
    }
  }
  .GROUP_COVER{
    width:80%;
    margin:1rem 10% 0;
    vertical-align: top;
  }
}
@media screen and(max-width:1440px) {
  .zhiboList .container .card img {
    height: 155px;
  }
}
</style>